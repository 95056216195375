.library-container {
  margin-top: 68.5px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5em;

  .library-scroll {
    padding: 0 24px;
    text-transform: uppercase;
    font-size: 14px;
  }

  .divider {
    padding: 0 24px;
  }

  .library-container {
    display: flex;
    margin: 0px;
    padding: 0 24px;

    .left-area {
      width: calc(100% / 12 * 6);
      max-width: calc(100% / 12 * 6);
      flex: 0 0 auto;
      flex-basis: calc(100% / 12 * 6);

      &-text {
        white-space: pre-line;
        padding-top: 24px;
      }

      .library-text {
        margin-top: 24px;
        text-align: justify;
        padding-right: 100px;
        line-height: 20px;
        font-size: 14px;

        a {
          color: red;
        }
      }

      .title {
        color :red;
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    .right-area {
      width: calc(100% / 12 * 6);
      max-width: calc(100% / 12 * 6);
      flex: 0 0 auto;
      flex-basis: calc(100% / 12 * 6);
      padding-left: 24px;
      display: flex;
      align-items: center;

      .carousel-image {
        height: auto;
        width: 100%;
      }

      //.control-arrow {
      //  background: rgba(0,0,0,0.2);
      //  opacity: 1;
      //}
    }
  }

  .library-container:last-child {
    padding-bottom: 40px;
  }

  @media screen and (max-width: 768px) {
    .library-container {
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 16px;




      .left-area, .right-area {
        width: 100%;
        max-width: none;
        flex-basis: 100%;
      }

      .right-area {
        padding-left: 0;
      }
    }

    .library-scroll {
      padding: 0 16px;
    }
  }
}
