
body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url("../public/assets/images/cursor-black.png"), default !important;
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home-container {
  margin-top: 68.5px;
  height: calc(100vh - 68.5px - 61.5px);
  background-image: url("https://static.strezoski.com/video_converted_2.gif");
  background-repeat: no-repeat;
  background-size: auto 80%;
  background-position: 50% 50%;
  background-attachment: fixed;
  background-color: #ffffff;

  @media screen and (max-width: 768px) {
    height: calc(100vh - 68.5px - 91px);
  }

  @media screen and (max-width: 992px) {
    background-size: contain;
  }
}

a, button {
  color: black;
  text-decoration: none;
  cursor: url("../public/assets/images/cursor-red.png"), default !important;
}

a:hover, button:hover {
  color: red;
}

.divider {
  border: 1px solid black;
  margin: 40px 15px;
}
