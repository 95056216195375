.press-container {
  margin-top: 68.5px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5em;

  .library-scroll {
    padding: 0 24px;
    font-size: 14px;
  }

  .divider {
    padding: 0 24px;
  }

  .press-container {
    display: flex;
    margin: 0px;
    padding: 0 24px;

    .left-area {
      font-size: 14px;
      width: calc(100% / 12 * 4);
      max-width: calc(100% / 12 * 4);
      flex: 0 0 auto;
      flex-basis: calc(100% / 12 * 4);

      &-text {
        white-space: pre-line;
        padding-top: 24px;
      }
    }

    .right-area {
      width: calc(100% / 12 * 8);
      max-width: calc(100% / 12 * 8);
      flex: 0 0 auto;
      flex-basis: calc(100% / 12 * 8);
      padding-left: 24px;
      display: flex;
      align-items: center;

      .carousel-image {
        height: auto;
        width: 100%;
      }

      //.control-arrow {
      //  background: rgba(0,0,0,0.2);
      //  opacity: 1;
      //}
    }
  }

  .press-container:last-child {
    padding-bottom: 40px;
  }

  @media screen and (max-width: 768px) {
    .press-container {
      flex-wrap: wrap;
      justify-content: center;

      .left-area, .right-area {
        width: 100%;
        max-width: none;
        flex-basis: 100%;
      }

      .right-area {
        padding-left: 0;
      }
    }
  }
}