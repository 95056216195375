.navbar-container {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99999;

  background-color: rgba(255, 255, 255, 0.5);

  a {
    font-size: 12px;
  }

  .MuiAppBar-root {
    background-color: transparent;
    color: #020202;
    box-shadow: none;

    .MuiContainer-root {
      max-width: none;
    }

    .navbar-button {
      color: #020202;
      font-weight: 400;
    }

    .white-text > a {
      color: #fff;
    }
  }

  .logo {
    height: 68.5px;
  }


}

.menu-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  top: 0;
  background-color: white;
  left: 0;
  z-index: 9999999;

  .MuiTypography-root {
    font-size: 24px;
    padding: 10px 0;
    letter-spacing: 0.5px;

    a {
      color: rgb(136, 136, 136);
      font-weight: 300;
    }

    a:hover {
      color: red;
    }
  }
}

.MuiPopover-paper {
  width: 100vw !important;
  height: 100vh !important;
  max-height: 100vh !important;
  max-width: 100vh !important;
  display: block;
  top: 0 !important;
  left: 0 !important;
}

.active {
  a {
    color: red !important;
  }
}