.footer-wrapper {

  .footer-container {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 10px 24px;
    font-size: 14px;
    font-weight: 300;

    .social-link {
      padding: 15px;
    }

    &:last-child {
      padding-right: 9px;
    }
  }
}

.bump-bottom {
  position: fixed;
  bottom: 0;
  width: 100vw;
}

@media screen and (max-width: 768px) {
  .footer-wrapper {

    .footer-container {
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 0;

      .left-area, .center-area, .right-area {
        flex: 1 1 100%;
        text-align: center;
        padding: 3px 0;
      }
    }
  }
}