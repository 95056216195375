.container {
  padding-top: 68.5px;


  .works-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 16.5px;

    .work-container {
      width: 25%;
      padding: 0 7.5px 30px;
      max-width: 25%;
      flex: 0 0 auto;
      flex-basis: 25%;
      text-align: center;

      .work-name {
        text-transform: uppercase;
      }

      .main-image {
        filter: grayscale(1);
        width: 100%;

        @media (hover: none) {
          filter: none;
        }
      }

      .main-image:hover {
        filter: none;
        transition: filter .25s;
      }
    }

    @media screen and (max-width: 768px) {
      .work-container {
        width: 100%;
        max-width: unset;
        flex-basis: 100%;
        padding: 0 0 30px;
      }
    }
  }

  .filter-container {
    padding-bottom: 40px;
    padding-left: 16.5px;
    display: inline-block;

    .filter {
      padding: 0 7.5px;
      font-size: 12px;
      display: block;
      text-transform: uppercase;
    }

    .active {
      color: red;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 68.5px 8.5px 0;
  }
}
