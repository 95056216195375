.library-item-container {
  padding-top: 68.5px;
  font-weight: 300;
  min-height: calc(100vh - 8.5px);
  display: flex;
  flex-direction: column;


  .title {
    flex: 0 0 100%;
    padding: 0 24px;
    margin-bottom: 24px;
    font-size: 18px;
  }

  .text-container {
    padding: 0 24px;
    white-space: pre-line;
  }

  .columns {
    columns: 2
  }

  .footer-wrapper {
    margin-top: auto;
  }
}