.parallax {
  /* The image used */

  /* Set a specific height */
  min-height: 100vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

      background-attachment:scroll !important;
      background-size: cover;
      height: 100%;
      overflow: hidden;

  }
}

.main-info {
  padding: 68.5px 8.5px 0;
  display: flex;
  flex-wrap: wrap;
}

.info-left {
  width: calc(100% / 12 * 5);
  font-size: 27px;
  font-weight: 300;
  padding: 40px 15px;

  a {
    color: red;
  }

  .project-name {
    color: red;
  }
}

.info-right {
  width: calc(100% / 12 * 7);
  padding: 40px 15px;
  font-size: 18px;
  font-weight: 300;
}

.standard-size {
  font-size: 18px;
  white-space: pre-line;
  vertical-align: bottom;
}

.additional-info {
  display: flex;
  flex-wrap: wrap;
  padding: 0 8.5px;
}

.secondary-image {
  width: 100%;
}

.gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .image-container, .video-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .carousel-root {
      max-height: 67.5vh;
      max-width: calc(80vh * 16 / 9);

      .carousel-image {
        width: auto;
        height: 80vh;
      }
    }
  }

  .gallery-image {
    max-height: 67.5vh;
    max-width: 50%;
  }

  .slide {
    overflow: hidden;
  }
}

@media screen and (max-width: 992px) {
  .container {
    padding: 68.5px 0 0 0;

    .filter-container {
      padding: 0 8.5px 40px 8.5px;
    }
  }

  .main-info, .additional-info {
    padding-left: 0;
    padding-right: 0;
  }
  .gallery {
    .gallery-image {
      max-width: 85%;
    }

    .image-container {
      height: 90vh;

      .carousel-root {
        max-width: 85%;
        height: auto;
        max-height: none;

        .carousel-image {
          max-width: 100%;
        }
      }
    }
  }

  .info-left, .info-right {
    width: 100%;
    padding: 20px 15px;
  }

  .parallax {
    min-height: 50vh;
    background-position: top;
    background-size: contain;
  }
}

@media screen and (max-width: 600px) {
  .carousel-image {
    width: 100% !important;
    height: auto !important;
  }
}