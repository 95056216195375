.designs-container {
  padding-top: 68.5px;

  .design-container {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;

    .left-area {
      width: calc(100% / 12 * 5);
      max-width: calc(100% / 12 * 5);
      flex: 0 0 auto;
      flex-basis: calc(100% / 12 * 5);
    }

    .right-area {
      width: calc(100% / 12 * 6);
      max-width: calc(100% / 12 * 6);
      flex: 0 0 auto;
      flex-basis: calc(100% / 12 * 6);
      font-weight: 300;

      .name, .type {
        font-size: 30px;
      }

      .name {
        color: red;
      }

      .subtitle-container {
        padding-top: 20px;
      }

      .subtitle {
        font-size: 18px;
        white-space: pre-line;
        vertical-align: bottom;

        a {
          color: red;
        }
      }

      .description {
        font-size: 18px;
        white-space: pre-line;
        vertical-align: bottom;
        padding-top: 20px;
      }
    }

    .small-divider {
      border: 0.5px solid black;
      margin: 9.5px 0;
      width: 15px;
    }
  }

  @media screen and (max-width: 768px) {
      .design-container {
        flex-wrap: wrap;

        .left-area, .right-area {
          max-width: none;
          width: 100%;
          flex-basis: 100%;
        }

        .left-area {
          margin-bottom: 24px;
        }
      }
    }
}