.about-us-container {
  padding: 68.5px 30px;

  font-size: 18px;
  font-weight: 300;

  @media screen and (max-width: 768px) {
    padding: 68.5px 16px;
  }

    .main {
    height: calc(100vh - 68.5px - 82px);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 768px) {
      height: auto;
    }
  }

  .activities, .team, .contact {
    display: flex;
  }

  .left-area {
    text-transform: uppercase;
    width: calc(100% / 12 * 4);
    max-width: calc(100% / 12 * 4);
    flex: 0 0 auto;
    flex-basis: calc(100% / 12 * 4);
    padding-right: 15px;
  }

  .right-area {
    width: calc(100% / 12 * 8);
    max-width: calc(100% / 12 * 8);
    flex: 0 0 auto;
    flex-basis: calc(100% / 12 * 8);
    padding-left: 15px;

    .no-bullets {
      list-style-type: none;
    }

    li {
      margin: 15px 0;
    }
  }

  .divider {
    border-color: black;
    margin: 40px 0;
  }

  @media screen and (max-width: 768px) {
    .activities, .team, .contact {
      flex-wrap: wrap;
    }
    .left-area, .right-area {
      max-width: 100%;
      width: 100%;
      flex-basis: 100%;
    }

  }
}